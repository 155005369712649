import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx"
import ConditionalBreadcrumb from "../components/conditionalbreadcrumb";
import RSTags from "../components/tags";
import Seo from "../components/seo";
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import { TitleBar } from "../components/titlebar";
import { Header } from "../components/header";
import { Footer } from "../components/footer";
export default function BlogPostTemplate({ location, data, pageContext }) {

  const frontmatter = data.mdx.frontmatter
  const body = data.mdx.body

  const tags = frontmatter.tags ?? []

  const format = frontmatter.format ?? "normal"

  const containerClasses = format === "wide" ? "col px-0" : "col-md-10 col-lg-8 px-0"

  function innerLayout(format, body) {
    if (format === "single") {
      return <div class="col gx-0 ">
        <MDXRenderer >{body}</MDXRenderer>
      </div>
    }
    else {
      return <div class="row">
        <div class="col-md-8">
          <MDXRenderer >{body}</MDXRenderer>
        </div>
        <div class="col-lg-4 mu-6"><RSTags tagList={tags} /></div>
      </div>
    }
  }
  return (
    <React.Fragment>
      <Header containerClasses="container" />
      <Seo title={frontmatter.title} pathname={location.path} description={frontmatter.excerpt} />
      <TitleBar title={frontmatter.title} location={location} crumbs={pageContext.breadcrumb}></TitleBar>
      <div class="container py-3">
      {innerLayout(format, body)}
      </div>
      <Footer containerClasses="container" />
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        slug
        tags
        format
        excerpt
      }
      id
      body
    }
  }
`;
